import React from 'react';
import styles from './packages.module.scss';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomButton from '../../common/components/CustomButton';
import { useSelector } from 'react-redux';
import Bullet from './Bullet';
import { useGetAllProfilesQuery } from '../../services/apis/ProfileService';
import { useGetAccountDetails } from '../../common/hooks/useGetAccountDetails';

export default function PackageCard({
  index,
  id,
  packageItem,
  handleButtonClick,
}) {
  const { items } = useSelector((state) => state.cart);
  const { account } = useGetAccountDetails();

  const { data: allProfiles } = useGetAllProfilesQuery(account?.accountId, {
    skip: !Boolean(account?.accountId),
  });

  const isSelected = items.find((item) => item.id === id);

  const isPackageDisabled = (id) => {
    const isBirthdayPackSelected = items.some((item) => item.id === 6);
    if (allProfiles && allProfiles.length <= 0) {
      return true;
    }
    if (isBirthdayPackSelected && id !== 6) {
      return true;
    } else if (items.length > 0 && !isBirthdayPackSelected && id === 6) {
      return true;
    }
  };

  const getSelectButtonText = (id) => {
    const isBirthdayPackSelected = items.some((item) => item.id === 6);
    if (allProfiles && allProfiles.length <= 0) {
      return 'Please Add a Child ';
    }
    if (isBirthdayPackSelected && id !== 6) {
      return 'Can Not Combine';
    }
    if (items.length > 0 && !isBirthdayPackSelected && id === 6) {
      return 'Can Not Combine';
    }
    return 'Select';
  };
  const getSelectButtonTooltipText = (id) => {
    const isBirthdayPackSelected = items.some((item) => item.id === 6);
    if (allProfiles && allProfiles.length <= 0) {
      return '"Please add a profile to select packages and buy playtime minutes."';
    }
    if (isBirthdayPackSelected && id !== 6) {
      return 'You can not combine regular packages with birthday package';
    }
    if (items.length > 0 && !isBirthdayPackSelected && id === 6) {
      return 'You can not combine birthday pack with another package';
    }
    return '';
  };

  return (
    <Box px={3} py={2} className={styles.customBoxShadow}>
      {packageItem.mostPopular && (
        <div className={styles.popular}>
          <p>Most Popular</p>
        </div>
      )}
      <Box
        mb={2}
        backgroundColor={index % 2 === 0 ? 'primary.main' : 'lightBlue.main'}
        py={0.5}
        width="100%"
      />
      <Box minHeight="165px" sx={{ fontFamily: 'SF Pro Display' }}>
        <Typography color={'black.light'} fontSize={'23px'} fontWeight={'600'}>
          {packageItem.packageName}
        </Typography>
        <Typography color={'#6F6F6F'} fontSize={'16px'} fontWeight={'400'}>
          {packageItem.packageDescription}
        </Typography>
        <Typography py={1} fontSize={'33px'} fontWeight={600} color={'primary'}>
          £{packageItem.price}
        </Typography>
        <Tooltip title={getSelectButtonTooltipText(id)} arrow followCursor>
          <Box>
            <CustomButton
              variant={isSelected ? 'outlined' : 'contained'}
              classes={styles.btn}
              sx={{
                color: isSelected ? 'primary.main' : 'textWhite.main',
                fontWeight: '600',
                boxShadow: 'none',
                fontSize: '19px',
                fontFamily: 'SF Pro Display',
              }}
              onClick={() => handleButtonClick(index)}
              disabled={isPackageDisabled(id)}
            >
              {isSelected ? 'Selected' : getSelectButtonText(id)}
            </CustomButton>
          </Box>
        </Tooltip>
      </Box>
      <Box
        border="1px solid"
        borderColor="borderLight.light"
        mt={2}
        width={'100%'}
      />
      <Bullet
        text={`${packageItem?.minutes} minutes`}
        sx={{ fontSize: '16px', color: '#4B3E3D' }}
      />
      {packageItem.features.map((feature, featureIndex) => (
        <Bullet
          key={featureIndex}
          text={feature}
          sx={{ fontSize: '16px', color: '#4B3E3D' }}
        />
      ))}
    </Box>
  );
}
